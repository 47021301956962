
export default {
	name: 'mouthStatistics',
	data() {
		return {
			yaerAll: '',
			list: '',
			year: this.$store.state.result.substring(0, 4),
			optionList: '',
			total: 0,
			pageSize: 0,
			currentPage: 1,
			monthList: [{
				name: '全部',
				value: ''
			},
			{
				name: '1月',
				value: '01'
			},
			{
				name: '2月',
				value: '02'
			},
			{
				name: '3月',
				value: '03'
			},
			{
				name: '4月',
				value: '04'
			},
			{
				name: '5月',
				value: '05'
			},
			{
				name: '6月',
				value: '06'
			},
			{
				name: '7月',
				value: '07'
			},
			{
				name: '8月',
				value: '08'
			},
			{
				name: '9月',
				value: '09'
			},
			{
				name: '10月',
				value: '10'
			},
			{
				name: '11月',
				value: '11'
			},
			{
				name: '12月',
				value: '12'
			},
			],
			month: this.$store.state.result.substring(5, 7),
			yearSel: '',
			searchers: false,

		}
	},

	created() {
		this.token = this.getCookie('token')
		this.getmouthStatistics()
	},
	methods: {
		getmouthStatistics() {
			var _this = this;
			this.searchers = false;
			_this.$http.post('/API/PLAT/inPageD', {
				year: _this.year,
				page: _this.currentPage
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.optionList = response.data.data.yearList;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			if (this.searchers) {
				this.searchBtn()
			} else {
				this.getmouthStatistics()
			}
		},
		searchBtn() {
			var _this = this;
			this.searchers = true;
			_this.yearSel = _this.year + "-" + _this.month
			if (_this.yearSel == "-") {
				_this.yearSel = ""
			}
			//				console.log(_this.yearSel)
			_this.$http.post('/API/PLAT/inPageD', {
				year: _this.yearSel,
				page: _this.currentPage
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		toCrcMena() {
			this.$router.push('/statistics')
		},
		toPro(id) {
			this.$router.push({
				name: 'projectParticulars',
				params: {
					id: id
				}
			})
		},
		//导出
		export2Excel() {
			var _this = this;
			if (_this.list) {
				_this.$http.post('/API/PLAT/inPageDExcel', {
					year: _this.year,
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (response) {
					require.ensure([], () => {
						const {
							export_json_to_excel
						} = require('../../vendor/Export2Excel');
						const tHeader = ['科室', '项目简称', '项目名称', '适应症', '启动日期'];
						const filterVal = ['departmentName', 'shortName', 'projectName', 'diseaseName', 'startTime'];
						const data = _this.formatJson(filterVal, response.data.data.objArray);
						export_json_to_excel(tHeader, data, '月度统计详情列表');
					})
				});
			} else {
				this.$message.error('操作异常');
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
